'use client'

import { useEffect, useRef, useState } from 'react'

interface HeadingData {
  id: string;
  text: string;
}

export function TableOfContents() {
  const [activeHeadingId, setActiveHeadingId] = useState('')
  const [headings, setHeadings] = useState<any | null>([])
  const headingsRef = useRef({})

  useEffect(() => {
    const observerHandler = (observed: any) => {
      headingsRef.current = observed.reduce((map: any, elem: any) => {
        map[elem.target.id] = elem
        return map
      }, headingsRef.current)

      const visibleHeadings: { target: { id: string } }[] = []

      Object.keys(headingsRef.current).forEach((key: string) => {
        const headingElement = (headingsRef.current as any)[key]
        if (headingElement.isIntersecting) {
          visibleHeadings.push(headingElement)
        }
      })

      const getIndexFromId = (id: string) => (
        headingElements.findIndex((heading) => heading.id === id)
      )

      if (visibleHeadings.length === 1) {
        setActiveHeadingId(visibleHeadings[0].target.id)
      }
      else if (visibleHeadings.length > 1) {
        const sortedVisibleHeadings = visibleHeadings.sort(
          (a, b) => getIndexFromId(a.target.id) > getIndexFromId(b.target.id) ? 1 : -1
        )
        setActiveHeadingId(sortedVisibleHeadings[0].target.id)
      }
    }
  
    const observer = new IntersectionObserver(observerHandler, {
      rootMargin: '0px 0px -30% 0px',
    })

    const headingElements = Array.from(document.querySelectorAll("article h2"))
    
    headingElements.forEach((element) => {
      observer.observe(element)
    })
    const found = headingElements.map((elem: any) => ({
      id: elem.id,
      text: elem.innerText,
    }))
    setHeadings(found)

    return () => observer.disconnect()
  }, [])

  return (
    <nav>
      <ul className="border-l-2 border-l-slate-200 dark:border-l-slate-700 pl-0 list-none">
        {headings.map((heading: HeadingData) => (
          <li
            key={heading.id}
            className={`block border-l-2 -ml-[2px] pl-4 transition-[color] duration-300 ease-in-out ${activeHeadingId === heading.id ? 'border-l-rose-400 dark:border-l-rose-600' : 'dark:border-l-slate-700'}`}
          >
            <a
              href={`#${heading.id}`}
              className={`block text-sm leading-6 py-1.5 tracking-tighter no-underline transition-[color] duration-300 ease-in-out ${activeHeadingId === heading.id ? 'text-slate-800 dark:text-slate-200' : 'text-slate-500 hover:text-slate-800 dark:text-slate-500 dark:hover:text-slate-200'}`}
              onClick={(e) => {
                e.preventDefault()
                const el = document.querySelector(`#${heading.id}`)!
                el.scrollIntoView({ behavior: "smooth" })
              }}
              >
              {heading.text}
            </a>
          </li>
        ))}
      </ul>
    </nav>
  )
}
