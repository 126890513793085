'use client'

import clsx from 'clsx'
import Link from 'next/link'
import { useCallback, useEffect, useState } from 'react'
import { Logo, NavLink } from '@/components'
import { Bars2Icon, XMarkIcon } from '@heroicons/react/24/solid'

export function MobileMenu() {
  const [ isOpen, setOpen ] = useState(false)

  const onClick = useCallback(() => {
    setOpen(false)
  }, [setOpen])

  const onToggle = useCallback(() => {
    setOpen(!isOpen)
  }, [isOpen, setOpen])

  useEffect(() => {
    if (isOpen) {
      document.body.classList.add('overflow-hidden')
    }
    else {
      document.body.classList.remove('overflow-hidden')
    }
  }, [isOpen])

  return (
    <div className="lg:hidden min-h-[72px] font-sans">
      <div className={clsx(
        'fixed dark:bg-[#121212]/70 px-5 left-0 right-0 top-0 z-50',
        {
          'bg-white/60 backdrop-blur-lg': !isOpen,
          'bg-white/80 backdrop-blur-xl': isOpen
        }
      )}>
        <div className={clsx(
          'flex flex-col',
          {
            'h-[4.5rem]': !isOpen,
            'h-screen': isOpen
          }
        )}>
          <div className="flex grow-0 items-center justify-between py-4">
            <NavLink href="/" className="lowercase text-slate-800 dark:text-white" onClick={onClick}>
              <Logo className="text-slate-900" />
            </NavLink>
            <button
              className={clsx(
                'w-10 h-10 flex justify-center items-center transition-all duration-100',
                {
                  'rotate-90': isOpen,
                }
              )}
              onClick={onToggle}
            >
              {isOpen ? (
                <XMarkIcon className="h-7 w-7" />
              ) : (
                <Bars2Icon className="h-7 w-7" />
              )}
            </button>
          </div>
          {isOpen && (
            <>
              <div className="flex flex-col grow py-8 shrink-0">
                <ul className="border border-slate-300 mb-5 rounded-lg text-center w-full">
                  <li>
                    <NavLink href="/" className="border-b border-slate-300 text-md font-semibold tracking-tight py-5" onClick={onClick}>Home</NavLink>
                  </li>
                  <li>
                    <NavLink href="/design-sprints" className="border-b border-slate-300 text-md font-semibold tracking-tight py-5" onClick={onClick}>Design Sprints</NavLink>
                  </li>
                  <li>
                    <NavLink href="/customers" className="border-b border-slate-300 text-md font-semibold tracking-tight py-5" onClick={onClick}>Customer Stories</NavLink>
                  </li>
                  <li>
                    <NavLink href="/company" className="border-b border-slate-300 text-md font-semibold tracking-tight py-5" onClick={onClick}>Company</NavLink>
                  </li>
                  {/* <li>
                    <NavLink href="/blog" className="text-md font-semibold tracking-tight py-5" onClick={onClick}>Blog</NavLink>
                  </li> */}
                </ul>
              </div>
              <div className="py-6 w-full">
                <Link href="/discovery" onClick={onClick}>
                  <button className="btn btn-primary mb-4 py-5 rounded-lg text-md w-full">
                    Book a discovery call
                  </button>
                </Link>
                <Link href="/contact" onClick={onClick}>
                  <button className="btn btn-default py-5 rounded-lg text-md w-full">
                    Contact
                  </button>
                </Link>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  )
}
