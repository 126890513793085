import { FaInstagram } from '@react-icons/all-files/fa/FaInstagram'
import { FaLinkedinIn } from '@react-icons/all-files/fa/FaLinkedinIn'
import { FaYoutubeSquare } from '@react-icons/all-files/fa/FaYoutubeSquare'

let host = 'localhost:3000'
let protocol = 'http'

if (process.env.VERCEL_ENV && process.env.VERCEL_URL) {
  protocol = 'https'
  if (process.env.VERCEL_ENV === 'production') {
    host = 'www.hypervibrant.com'
  }
  else {
    host = process.env.VERCEL_URL
  }
}

export const siteConfig = {
  host,
  origin: `${protocol}://${host}`,
  protocol,
  cookieKeys: {
    consent: '_hcc'
  },
  git: {
    owner: 'dustinlarimer',
    repo: 'hypervibrant.com'
  },
  mailer: {
    from: 'hello@hypervibrant.com',
    to: 'hello@hypervibrant.com'
  },
  preview: {
    footnote: host
  },
  rss: {
    title: 'Hypervibrant',
    description: 'Hypervibrant blog'
  },
  calendlyUrls: {
    aiScopingSession: 'https://calendly.com/hypervibrant/ai-scoping-session',
    coachingCall: 'https://calendly.com/hypervibrant/free-coaching-session',
    discoveryCall: 'https://calendly.com/hypervibrant/discovery-call'
  },
  socials: [
    {
      title: 'Instagram',
      href: 'https://instagram.com/hypervibrant',
      Component: FaInstagram
    },
    // {
    //   title: 'YouTube',
    //   href: 'https://youtube.com/@hypervibrant',
    //   Component: FaYoutubeSquare
    // },
    // {
    //   title: 'TikTok',
    //   href: 'https://www.tiktok.com/@hypervibrant',
    //   Component: BiLogoTiktok
    // },
    {
      title: 'LinkedIn',
      href: 'https://linkedin.com/in/dustinlarimer',
      Component: FaLinkedinIn
    },
  ]
}

export const devContactFormValues = {
  name: 'Person Name',
  email: 'me@address.com',
  message: 'Hello, this is a test message!'
}

export const devDiscoveryFormValues = {
  email: process.env.NEXT_PUBLIC_DISCO_EMAIL || 'me@address.com',
  // email_consent: true,
  name: process.env.NEXT_PUBLIC_DISCO_NAME || 'Person Name',
  phone: '(000) 000-0000'
}
