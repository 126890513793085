'use client'

import clsx from 'clsx'

type Props = {
  children?: React.ReactNode
  className?: string
  dataForm: string
  id?: string
  isComplete?: boolean
}

export function MailerliteInlineForm({ 
  children,
  className,
  dataForm,
  id = "ml-form-embed",
  isComplete = false,
  ...rest
}: Props) {

  if (!!isComplete) {
    return (
      <>
        {children}
      </>
    )
  }

  return (
    <>
      <div
        className={clsx('ml-embedded', className)}
        data-form={dataForm}
        id={id}
        {...rest}
      />
      {/* <div id="ml-form-embed" className="ml-embedded md:max-w-[528px]" data-form="75IX3Z"></div> */}
    </>
  )
}
