'use client'

import clsx from 'clsx'
import Link from 'next/link'
import { usePathname } from 'next/navigation'

type Props = {
  children: React.ReactNode,
  className?: string,
  href?: string,
  onClick?: Function
}

export function NavLink(props: Props) {
  const { 
    children,
    className = '',
    href = '/',
    onClick
  } = props
  const pathname = usePathname() || ''
  const isActive = pathname === href || pathname.includes(href)

  return (
    <Link
      className={
        clsx(
          'block',
          'hover:text-slate-700 focus:text-slate-700 active:text-slate-700',
          'dark:hover:text-slate-200 dark:focus:text-slate-200',
          className,
          'text-slate-900 dark:text-slate-400'
          // {
          //   'text-slate-900 dark:text-slate-400': !isActive && !className,
          //   'text-sky-500 focus:text-sky-500 active:text-sky-600 dark:focus:text-sky-500 dark:active:text-sky-400': isActive && !className
          // }
        )
      }
      href={href}
      onClick={() => onClick ? onClick() : ()=>{}}
    >
      {children}
    </Link>
  )
}
