import { clsx } from 'clsx'
import Link from 'next/link'

interface QuoteType {
  className?: string
  id: "chickadee" | "garden"
}

const quotes = {
  'chickadee': {
    citation: {
      name: 'Kerry Kurian',
      title: 'CEO & Founder, Chickadee Financial'
    },
    href: '/customers/chickadee-product-strategy-sprint',
    quotation: `This sprint helped us take all the energy and raw excitement and point it in the same direction.`
  },
  'garden': {
    citation: {
      name: 'Eythor Magnusson',
      title: 'CTO & Cofounder, Garden'
    },
    href: '/customers/garden-design-sprint',
    quotation: `These sprints helped us execute on the things we've been talking about for months now. Absolutely recommend.`
  }
}

export function CustomerQuote({ className, id }: QuoteType) {
  const quote = quotes[id]
  if (!quote || !quote?.quotation) {
    return (<></>)
  }
  return (
    <>
      <Link href={quote?.href} className={clsx('bg-white block group py-16 md:py-48', className)}>
        <div className="container max-w-screen-lg mx-auto px-6">
          <blockquote className="text-center">
            <span className="bg-gradient-to-br from-cyan-700 to-rose-600 group-hover:from-cyan-600 group-hover:to-cyan-600 bg-clip-text block font-light italic leading-12 mb-9 text-xl md:text-3xl text-transparent tracking-tighter transition-all duration-1000 ease-in-out">
              <span className="pr-0.5">{`"`}</span>
              {quote?.quotation}
              <span>{`"`}</span>
            </span>
            <cite>
              <span className="block font-semibold leading-6 mb-1 not-italic text-slate-700 group-hover:text-cyan-800 text-sm">
                {quote?.citation.name}
              </span>
              <span className="block font-light leading-5 not-italic text-slate-700 group-hover:text-cyan-700 text-xs">
                {quote?.citation.title}
              </span>
            </cite>
          </blockquote>
        </div>
      </Link>
    </>
  )
}
