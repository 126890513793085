'use client'

import React from 'react'
import { ShaderGradientCanvas, ShaderGradient as ShaderGradientImpl } from 'shadergradient'
import * as reactSpring from '@react-spring/three'
import * as drei from '@react-three/drei'
import * as fiber from '@react-three/fiber'

export function ShaderGradient() {
  return (
    <div
      className="bg-white dark:bg-slate-950 blur-xl fade-in fixed min-h-screen w-full"
      style={{
        height: 'calc(100% + 100px)',
        left: '-50px',
        right: '-50px',
        top: '-50px',
        width: 'calc(100% + 100px)'
      }}
    >
      <div className="opacity-100 dark:opacity-30">
        <ShaderGradientCanvas
          eventPrefix='client'
          importedfiber={{
            ...drei,
            ...fiber,
            ...reactSpring
          }}
          fov={20}
          pixelDensity={0.7}
          style={{
            height: '100%',
            left: '0',
            minHeight: '100%',
            pointerEvents: 'none',
            position: 'absolute',
            right: '0',
            top: '0',
            width: '100%',
            zIndex: 0
          }}
        >
          <ShaderGradientImpl
            // control='query'
            // urlString='https://www.shadergradient.co/customize?animate=on&axesHelper=off&bgColor1=%23000000&bgColor2=%23000000&brightness=1.4&cAzimuthAngle=220&cDistance=4&cPolarAngle=30&cameraZoom=2.1&color1=%23caffba&color2=%23c2ddff&color3=%23ffbfc5&destination=onCanvas&embedMode=off&envPreset=city&format=gif&fov=40&frameRate=10&gizmoHelper=hide&grain=off&lightType=3d&pixelDensity=0.7&positionX=0&positionY=0&positionZ=0&range=disabled&rangeEnd=40&rangeStart=0&rotationX=70&rotationY=0&rotationZ=0&shader=defaults&type=waterPlane&uAmplitude=0&uDensity=1.5&uFrequency=0&uSpeed=0.1&uStrength=6&uTime=0&wireframe=false&zoomOut=false'
            animate="on"
            brightness={1.4}
            cAzimuthAngle={220}
            cDistance={4}
            cPolarAngle={30}
            cameraZoom={2.1}
            color1="#6EE7B7"
            color2="#93C5FD"
            color3="#FDA4AF"
            enableTransition={false}
            envPreset="city"
            frameRate={10}
            grain="off"
            lightType="3d"
            positionX={0}
            positionY={0}
            positionZ={0}
            range="disabled"
            rangeEnd={40}
            rangeStart={0}
            rotationX={70}
            rotationY={0}
            rotationZ={0}
            shader="defaults"
            type="waterPlane"
            uAmplitude={0}
            uDensity={1.5}
            uFrequency={0}
            uSpeed={0.1}
            uStrength={6}
            uTime={0}
            wireframe={false}
            zoomOut={false}
          />
        </ShaderGradientCanvas>
      </div>
    </div>
  )
}