'use client'

import { useEffect, useState, } from 'react'
import { Analytics } from '@vercel/analytics/react'
import Cookie from 'js-cookie'
import { GoogleAnalytics } from '@next/third-parties/google'
import Image from 'next/image'
import Script from 'next/script'
import { SpeedInsights } from '@vercel/speed-insights/next'
import { siteConfig } from '@/config'

function FacebookBeacon({ appendSrc }: { appendSrc: string }) {
  const concatSrc = `https://www.facebook.com/tr?id=${process.env.NEXT_PUBLIC_META_PIXEL_ID}${appendSrc}`
  return (
    <Image
      alt=""
      height={1}
      priority={true}
      src={concatSrc}
      style={{ display: "none" }}
      unoptimized={true}
      width={1}
    />
  )
}

export function ThirdPartyScripts() {
  const [cookieConsent, setCookieConsent] = useState('')

  useEffect(() => {
    let isMounted = true
    const interval = setInterval(async () => {
      const value = Cookie.get(siteConfig.cookieKeys.consent)
      if (!!value) {
        setCookieConsent(value)
        clearInterval(interval)
      }
    }, 500)
    return () => {
      clearInterval(interval)
      isMounted = false
    }
  }, [setCookieConsent])

  return (
    <>
      <SpeedInsights />
      {process.env.NEXT_PUBLIC_VERCEL_ENV !== 'development' && (
          <>
            {cookieConsent?.length > 0 && (
              cookieConsent === 'accepted' && (
                <>
                  <GoogleAnalytics gaId={process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_ID || ''} />
                  <Script
                    id="meta-pixel"
                    strategy="afterInteractive"
                  >
                    {`
                      !function(f,b,e,v,n,t,s)
                      {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                      n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                      if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                      n.queue=[];t=b.createElement(e);t.async=!0;
                      t.src=v;s=b.getElementsByTagName(e)[0];
                      s.parentNode.insertBefore(t,s)}(window, document,'script',
                      'https://connect.facebook.net/en_US/fbevents.js');
                      fbq('init', '${process.env.NEXT_PUBLIC_META_PIXEL_ID}');
                      fbq('track', 'PageView');
                    `}
                  </Script>
                  <noscript>
                    <FacebookBeacon appendSrc="&ev=PageView&noscript=1" />
                  </noscript>
                </>
              )
            )}
          </>
        )}
        <Analytics />
        <Script id="mailerlite">
          {`(function(w,d,e,u,f,l,n){w[f]=w[f]||function(){(w[f].q=w[f].q||[])
            .push(arguments);},l=d.createElement(e),l.async=1,l.src=u,
            n=d.getElementsByTagName(e)[0],n.parentNode.insertBefore(l,n);})
            (window,document,'script','https://assets.mailerlite.com/js/universal.js','ml');
            ml('account', '1017389');`}
        </Script>
    </>
  )
}
