import clsx from 'clsx'
import { CheckCircleIcon } from '@heroicons/react/24/outline'
import { Container } from '../container'

interface Props {
  className?: string
}

const painpoints = [
  `Products are built based on too many assumptions, not enough user insight.`,
  `The loudest voices dominate the creative process, not the best ideas.`,
  `Brainstorming spirals into debates, arguments, or political maneuvers.`,
  `Teams talk in circles and get lost in their ideas, without a clear path forward.`,
  `Projects tend to start cold, take way too long, and leave people underwhelmed.`,
  `Teams feel pressured to innovate, but don't know where or how to start.`,
  `Everyone is "too busy" but the big problems are not getting solved.`,
  `No shared vision of what success looks like or what to do next.`,
  `Morale is zapped by endless meetings to keep everyone on the same page.`
]

export function CustomerPains({ className }: Props) {
  return (
    <>
      <div className={clsx("bg-white py-16 md:py-36", className)}>
        <Container>
          <h2 className="font-bold font-serif mb-3 md:mb-2 mt-0 text-2xl md:text-3xl text-center">
            {`Collaboration breakdowns put your business at risk`}
            {/* {`Signs that broke teamwork is putting your business at risk`} */}
            {/* {`Signs your business is heading torward failure mode`} */}
            {/* {`Signs your team is ready for an upgrade`} */}
          </h2>
          <p className="font-light leading-8 mb-11 text-center text-md text-rose-700 tracking-tight">
            {`Dysfunctional collaboration is the silent killer of innovation at every level.`}
          </p>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-x-11 gap-y-5 md:gap-y-6 mb-8 px-6">
            {painpoints.map((text, i) => (
              <div className="flex" key={i}>
                <div className="mr-[10px] w-7">
                  <CheckCircleIcon className="h-7 text-rose-400 w-7" />
                </div>
                <p className="font-light grow leading-7 text-sm">{text}</p>
              </div>
            ))}
          </div>
        </Container>
      </div>
    </>
  )
}
