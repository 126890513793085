'use client'

import { useCallback, useState } from 'react'
import { 
  PopupModal,
  useCalendlyEventListener
} from 'react-calendly'
import { Loader } from '../loader'

interface CalendlyEmbedProps {
  className?: string
  onSubmit?: () => void
  prefill?: {
    email?: string
    firstName?: string
  }
  url: string
}

function CalendlyLoader() {
  return (
    <div className="flex h-full items-center justify-center w-full">
      <Loader color="white" />
    </div>
  )
}

export const CalendlyModal = ({ className, onSubmit, prefill, url }: CalendlyEmbedProps) => {
  const [isOpen, toggleOpen] = useState(true)

  const onToggle = useCallback(() => {
    toggleOpen(!isOpen)
  }, [isOpen, toggleOpen])

  useCalendlyEventListener({
    // onDateAndTimeSelected: () => console.log("onDateAndTimeSelected"),
    onEventScheduled: () => (!!onSubmit && onSubmit()),
    // onEventTypeViewed: () => console.log("onEventTypeViewed"),
    // onProfilePageViewed: () => console.log("onProfilePageViewed"),
  })

  if (typeof document === undefined) {
    return null
  }

  const rootElement = document.getElementById('root')

  if (!rootElement) {
    return null
  }

  return (
    <>
      <PopupModal
        onModalClose={onToggle}
        LoadingSpinner={CalendlyLoader}
        open={isOpen}
        pageSettings={{
          // backgroundColor: 'ffffff',
          // hideEventTypeDetails: false,
          // hideLandingPageDetails: false,
          // primaryColor: '00a2ff',
          // textColor: '4d5055'
        }}
        prefill={prefill}
        rootElement={rootElement}
        url={url}
        utm={{
          // utmCampaign: 'Spring Sale 2019',
          // utmContent: 'Shoe and Shirts',
          // utmMedium: 'Ad',
          // utmSource: 'Facebook',
          // utmTerm: 'Spring'
        }}
      />
    </>
  )
}
