interface LoaderType {
  className?: string
  color?: string
}

export function Loader({ className = '', color = '' }: LoaderType) {
  return (
    <div className={`flex h-6 items-center justify-center space-x-2 ${className}`}>
      <span className="sr-only">
        {`One moment, please...`}
      </span>
      {!!color ? (
        <>
          <div className={`h-4 w-4 bg-${color} rounded-full animate-bounce [animation-duration:600ms] [animation-delay:0ms]`}></div>
          <div className={`h-4 w-4 bg-${color} rounded-full animate-bounce [animation-duration:600ms] [animation-delay:125ms]`}></div>
          <div className={`h-4 w-4 bg-${color} rounded-full animate-bounce [animation-duration:600ms] [animation-delay:250ms]`}></div>
        </>
      ) : (
        <>
          <div className={`h-4 w-4 bg-cyan-400 rounded-full animate-bounce [animation-duration:600ms] [animation-delay:0ms]`}></div>
          <div className={`h-4 w-4 bg-indigo-400 rounded-full animate-bounce [animation-duration:600ms] [animation-delay:125ms]`}></div>
          <div className={`h-4 w-4 bg-rose-400 rounded-full animate-bounce [animation-duration:600ms] [animation-delay:250ms]`}></div>
        </>
      )}
    </div>
  )
}
