import clsx from 'clsx'
import Image from 'next/image'
import Link from 'next/link'
import { Container } from '../container'

interface Props {
  className?: string
}

const benefits = [
  {
    label: 'Create exciting new products and services in less time',
    imgSrc: '/images/benefit-graphic-1.svg'
  },
  {
    label: 'Tackle big complex problems with clarity and confidence',
    imgSrc: '/images/benefit-graphic-2.svg'
  },
  {
    label: 'Revitalize team collaboration and difficult group dynamics',
    imgSrc: '/images/benefit-graphic-3.svg'
  }
]

export function CallToAction({ className }: Props) {
  return (
    <>
      <div className={clsx("bg-cyan-100 py-16 md:py-36", className)}>
        <Container>
          <div className="flex flex-col md:flex-row justify-between">
            <div className="flex flex-col justify-center md:max-w-[528px] mb-12 md:mb-0 md:pr-6">
              <div>
                <h2 className="font-bold font-serif mb-3 mt-0 text-2xl md:text-4xl text-center md:text-left">
                  {`Discover teamwork at a higher frequency`}
                </h2>
                <p className="font-light leading-8 md:leading-9 mb-8 mt-0 text-cyan-700 text-lg text-center md:text-left">
                  {`Energizing, expertly-guided sprints and retreats designed to help your team do exceptional work together in a matter of days.`}
                </p>
                <Link href="/discovery">
                  <button className="btn btn-lg btn-primary mb-4 w-full md:w-auto">
                    Book a discovery call
                  </button>
                </Link>
                <div className="font-light text-xs text-slate-800 dark:text-slate-100 text-center md:text-left">
                  Limited availability for 2024.
                </div>
              </div>
            </div>
            <div className="flex flex-col gap-3 justify-center md:max-w-[528px]">
              {benefits.map(({ label, imgSrc }, i) => (
                <div className="bg-white flex items-center px-5 md:px-8 py-5 rounded-lg shadow-lg shadow-cyan-400/10" key={i}>
                  <Image
                    alt=""
                    className="mr-4 md:mr-6 shrink-0"
                    height={72}
                    unoptimized={true}
                    src={imgSrc}
                    width={72}
                  />
                  <span className="block font-light leading-6 md:leading-7 text-sm md:text-md">
                    {label}
                  </span>
                </div>
              ))}
            </div>
          </div>
        </Container>
      </div>
    </>
  )
}
