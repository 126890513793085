'use client'

import Link from 'next/link'
import {
  CloseButton,
  Popover,
  PopoverButton,
  PopoverPanel
} from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/20/solid'

import {
  Container,
  Logo,
  NavLink
} from '@/components'

export function DesktopMenu() {
  return (
    <Container className="hidden lg:flex flex-row justify-between items-center py-7 font-sans z-10">
      <NavLink href="/">
        <Logo className="text-slate-900" />
      </NavLink>
      <div className="flex flex-row items-center">
        <ul className="flex flex-row gap-5 items-center mr-6">
          <li>
            <NavLink href="/" className="text-xs">Home</NavLink>
          </li>
          <li>
            <NavLink href="/design-sprints" className="text-xs">Design Sprints</NavLink>
          </li>
          <li>
            <NavLink href="/customers" className="text-xs">Customer Stories</NavLink>
          </li>
          <li>
            <NavLink href="/company" className="text-xs">Company</NavLink>
          </li>
          {/* <li>
            <NavLink href="/" className="text-xs">Sprints</NavLink>
          </li>
          <li>
            <NavLink href="/" className="text-xs">Retreats</NavLink>
          </li> */}
          {/* <li>
            <Popover className="group">
              <PopoverButton className="flex items-center text-xs outline-none">
                Resources
                <ChevronDownIcon className="text-slate-800/85 h-5 w-5 group-data-[open]:rotate-180 transition-all duratio-150 ease-in-out" />
              </PopoverButton>
              <PopoverPanel anchor="bottom" className="bg-white fade-in flex flex-col mt-3 py-3 px-5 rounded-lg shadow-lg min-w-[168px]">
                <CloseButton as={Link} href="/customers" className="leading-8 text-xs hover:text-rose-600">Customer Stories</CloseButton>
                <CloseButton as={Link} href="/company" className="leading-8 text-xs hover:text-rose-600">Company</CloseButton>
              </PopoverPanel>
            </Popover>
          </li> */}
          {/* <li>
            <NavLink href="/blog" className="text-xs">Blog</NavLink>
          </li> */}
        </ul>
        <Link href="/discovery">
          <button className="btn btn-sm btn-primary">Book a discovery call</button>
        </Link>
      </div>
    </Container>
  )
}
