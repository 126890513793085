'use client'

import dynamic from 'next/dynamic'
import { Suspense, useCallback, useState } from 'react'
import clsx from 'clsx'
import { 
  Formik,
  FormikHelpers,
  Form,
  Field as FormikField,
  FieldProps
} from 'formik'
import { sendGAEvent } from '@next/third-parties/google'

import {
  Button,
  // Checkbox,
  Field,
  Input,
  Label
} from '@headlessui/react'

import { 
  // Link,
  Loader
} from '@/components'
import { devDiscoveryFormValues, siteConfig } from '@/config'
import { isDev } from '@/lib/utils'

const CalendlyModal = dynamic(() => import('@/components/calendly/calendly-modal').then((mod) => mod.CalendlyModal), { ssr: false })

interface Props {
  onComplete?: () => void
}

export interface DiscoveryFormValues {
  email?: string
  // email_consent?: boolean
  name?: string
  phone?: string
}

export function DiscoveryForm({ onComplete }: Props) {
  const [error, setError] = useState("")
  const [scheduled, setScheduled] = useState(false)
  const [success, setSuccess] = useState("")

  const initialValues: DiscoveryFormValues = isDev(devDiscoveryFormValues, {
    email: '',
    // email_consent: true,
    name: '',
    phone: '',
  })

  const handleCompletion = useCallback(() => {
    setScheduled(true)
    if (typeof window !== 'undefined' && !!window?.fbq) {
      window.fbq('track', 'Schedule')
    }
  }, [setScheduled])

  const handleSubmit = useCallback(async (values: DiscoveryFormValues, { resetForm, setSubmitting }: FormikHelpers<DiscoveryFormValues>): Promise<void> => {
    await new Promise((resolve, reject) => {
      setError("")
      setSuccess("")
      fetch("/api/discovery", {
        method: "post",
        body: JSON.stringify(values),
      })
        .then(async (res) => {
          const json = await res?.json()
          // setSubmitting(false)
          if (res.ok) {
            setSuccess(json.message)
            if (onComplete) {
              onComplete()
            }
            sendGAEvent('event', 'book_discovery_call')
            if (typeof window !== 'undefined' && !!window?.fbq) {
              window.fbq('track', 'Lead', { content_name: 'Discovery Call' })
            }
            resolve(res)
          }
          else {
            setError(json.message)
            reject(json.message)
          }
        })
        .catch(async (err) => {
          setSubmitting(false)
          if (err) {
            if (err.message === "Failed to fetch") {
              setError("Failed to send, please check your internet connection and try again")
            }
            else {
              setError(err.message)
            }
            reject(err)
          }
        })
    })
  }, [onComplete])

  const handleValidate = useCallback((values: DiscoveryFormValues) => {
    const errors: DiscoveryFormValues = {}
    if (!values.name) {
      errors.name = "Name is required"
    }
    if (!values.email) {
      errors.email = "Email address is required"
    }
    if (!values.phone) {
      errors.phone = "Phone number is required"
    }
    // console.log(values, errors)
    return errors
  }, [])

  return (
    <div className="w-full">
      {!!error && (
        <div className="bg-red-100 dark:bg-red-950/50 border-l-2 border-l-red-500 dark:border-l-red-600 mb-8 px-5 py-[14px] text-red-700 dark:text-white">
          {error || "An error occured"}
        </div>
      )}

      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validate={handleValidate}
      >
        {({ errors, isSubmitting, touched, values }) => (
          <Form>
            {(!!success && !isSubmitting) ? (
              <div className="flex flex-col items-center justify-center mb-8 py-24">
                <div className="flex items-center justify-center leading-5 mb-6 text-md font-semibold">
                  {!!scheduled ? (
                    `Thanks, you're booked!`
                  ) : (
                    `Thanks! We'll be in touch soon.`
                  )}
                </div>
                {/* <p className="max-w-[320px] mb-5 text-center">
                  {`In the meantime, check out more Hypervibrant goodies here:`}
                </p>
                <ul className="flex flex-row items-center space-x-2">
                  {siteConfig.socials.map(({ Component, href = '', title = '' }, i) => (
                    <li key={i}>
                      <Link
                        className="bg-slate-800 hover:bg-slate-500 dark:hover:bg-slate-200 h-12 w-12 rounded-full flex items-center justify-center text-white transition-colors duration-100"
                        external={true}
                        href={href}
                        title={title}
                      >
                        <Component className="h-6 w-6" />
                      </Link>
                    </li>
                  ))}
                </ul> */}
              </div>
            ) : (
              <>
                <div className="mb-6">
                  <FormikField name="name">
                    {({ field, form: { isSubmitting }, meta }: FieldProps) => (
                      <Field disabled={isSubmitting}>
                        <Label
                          className="data-[disabled]:opacity-50 block text-slate-800 dark:text-white text-sm font-semibold mb-2"
                          htmlFor="name"
                        >
                          First Name
                        </Label>
                        <Input
                          {...field}
                          aria-required={true}
                          autoComplete="name"
                          className={clsx(
                            'data-[disabled]:opacity-50 data-[disabled]:bg-gray-100',
                            'appearance-none block leading-tight mb-2 px-4 py-3 rounded-md transition-colors w-full',
                            'bg-transparent text-gray-700 dark:text-white text-xs',
                            'focus:outline-none border',
                            {
                              'bg-red-50 border-red-400/50 focus:border-red-400': !!errors.name && !!touched.name,
                              'border-slate-400/50 focus:border-slate-400 sdark:border-neutral-600 dark:focus:border-sky-600': !errors.name,
                            }
                          )}
                          id="name"
                          disabled={isSubmitting}
                          required={true}
                          type="text"
                        />
                        {meta.touched && meta.error && (
                          <div className="mt-2 text-red-500 text-xs">
                            {meta.error}
                          </div>
                        )}
                      </Field>
                    )}
                  </FormikField>
                </div>

                <div className="mb-6">
                  <FormikField name="email">
                    {({ field, form: { isSubmitting }, meta }: FieldProps) => (
                      <Field disabled={isSubmitting}>
                        <Label
                          className="data-[disabled]:opacity-50 block text-slate-800 dark:text-white text-sm font-semibold mb-2"
                          htmlFor="email"
                        >
                          Email Address
                        </Label>
                        <Input
                          {...field}
                          aria-required={true}
                          autoComplete="email"
                          className={clsx(
                            'data-[disabled]:opacity-50 data-[disabled]:bg-gray-100',
                            'appearance-none block leading-tight mb-2 px-4 py-3 rounded-md transition-colors w-full',
                            'bg-transparent text-gray-700 dark:text-white text-xs',
                            'focus:outline-none border',
                            {
                              'bg-red-50 border-red-400/50 focus:border-red-400': !!errors.email && !!touched.email,
                              'border-slate-400/50 focus:border-slate-400 sdark:border-neutral-600 dark:focus:border-sky-600': !errors.email,
                            }
                          )}
                          id="email"
                          disabled={isSubmitting}
                          required={true}
                          type="text"
                        />
                        {meta.touched && meta.error && (
                          <div className="mt-2 text-red-500 text-xs">
                            {meta.error}
                          </div>
                        )}
                      </Field>
                    )}
                  </FormikField>
                </div>
                
                <div className="mb-6">
                  <FormikField name="phone">
                    {({ field, form: { isSubmitting }, meta }: FieldProps) => (
                      <Field disabled={isSubmitting}>
                        <Label
                          className="data-[disabled]:opacity-50 block text-slate-800 dark:text-white text-sm font-semibold mb-2"
                          htmlFor="phone"
                        >
                          Phone Number
                        </Label>
                        <Input
                          {...field}
                          aria-required={true}
                          autoComplete="tel"
                          className={clsx(
                            'data-[disabled]:opacity-50 data-[disabled]:bg-gray-100',
                            'appearance-none block leading-tight mb-2 px-4 py-3 rounded-md transition-colors w-full',
                            'bg-transparent text-gray-700 dark:text-white text-xs',
                            'focus:outline-none border',
                            {
                              'bg-red-50 border-red-400/50 focus:border-red-400': !!errors.phone && !!touched.phone,
                              'border-slate-400/50 focus:border-slate-400 sdark:border-neutral-600 dark:focus:border-sky-600': !errors.phone,
                            }
                          )}
                          id="phone"
                          disabled={isSubmitting}
                          required={true}
                          type="text"
                        />
                        <div className="pt-1 text-slate-600 text-xs">
                          {`Please include country code if outside of the United States.`}
                        </div>
                        {meta.touched && meta.error && (
                          <div className="mt-2 text-red-500 text-xs">
                            {meta.error}
                          </div>
                        )}
                      </Field>
                    )}
                  </FormikField>
                </div>
                
                {/* <div className="mb-6">
                  <FormikField name="email_consent">
                    {({ field, form: { isSubmitting, setFieldValue }, meta }: FieldProps) => (
                      <Field className="mb-8" disabled={isSubmitting}>
                        <div className="flex items-start gap-2 mb-2">
                          <Checkbox
                            {...field}
                            aria-required={true}
                            checked={field.value}
                            className={clsx(
                              'block border cursor-pointer data-[disabled]:opacity-50 data-[checked]:bg-cyan-500 data-[checked]:border-cyan-500 data-[disabled]:bg-gray-100 group mt-0.5 shrink-0 w-5',
                              'rounded-md transition-colors',
                              'focus:outline-none',
                              {
                                'bg-red-50 border-red-400/50 focus:border-red-400': !!errors.email_consent && !!touched.email_consent,
                                'border-slate-400/50 focus:border-slate-400 sdark:border-neutral-600 dark:focus:border-sky-600': !errors.email_consent,
                              }
                            )}
                            id="email_consent"
                            disabled={isSubmitting}
                            onChange={isChecked => setFieldValue("email_consent", isChecked)}
                          >
                            <svg className="stroke-white opacity-0 group-data-[checked]:opacity-100" fill="none" viewBox="0 0 14 14">
                              <path d="M3 8L6 11L11 3.5" strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} />
                            </svg>
                          </Checkbox>
                          <Label
                            className="block cursor-pointer data-[disabled]:opacity-50 font-medium leading-6 text-slate-800 dark:text-white text-xs"
                            htmlFor="email_consent"
                          >
                            {`Sign me up for occasional emails loaded with helpful tips, free resources, and exclusive offers. `}
                            <span className="font-normal pt-1 text-slate-500">
                              {`(Opt-out at any time)`}
                            </span>
                          </Label>
                        </div>
                        {meta.touched && meta.error && (
                          <div className="text-red-500 text-sm">
                            {meta.error}
                          </div>
                        )}
                      </Field>
                    )}
                  </FormikField>
                </div> */}

                <Button
                  className={clsx(
                    'btn btn-lg btn-primary mt-3 w-full md:w-auto',
                    'data-[disabled]:opacity-50'
                  )}
                  disabled={isSubmitting}
                  type="submit"
                >
                  {!isSubmitting ? (
                    `Continue to scheduling`
                  ) : (
                    <Loader className="scale-75 -mb-1" color="white" />
                  )}
                </Button>
              </>
            )}
            <Suspense fallback={null}>
              {!!success && (
                <CalendlyModal
                  onSubmit={handleCompletion}
                  prefill={values}
                  url={siteConfig.calendlyUrls.discoveryCall}
                />
              )}
            </Suspense>
          </Form>
        )}
      </Formik>
    </div>
  )
}
