import clsx from 'clsx'
import Image from 'next/image'
import Link from 'next/link'
import { Container } from '../container'

interface Props {
  className?: string
}

export function CompanyPitch({ className }: Props) {
  return (
    <>
      <div className={clsx("bg-white/80 backdrop-blur-xl py-16 md:py-36", className)}>
        <Container>
          <div className="flex flex-col lg:flex-row justify-between">
            <div className="flex flex-col justify-center lg:max-w-[528px] mb-12 lg:mb-0 lg:pr-6 text-center lg:text-left">
              <h2 className="font-bold font-serif mb-3 mt-0 text-2xl md:text-4xl">
                {`Break free from collaboration chaos`}
              </h2>
              <p className="font-light leading-8 md:leading-9 mb-3 text-cyan-700 text-lg md:text-xl">
                {`Innovation is a team sport, but few will ever learn how to play it well.`}
              </p>
              <p className="font-light leading-8 mb-8 text-md">
                {`Hypervibrant was created to help you do exactly that, through carefully crafted processes that unlock and harness higher levels of creativity in a short amount of time.`}
              </p>
              <Link href="/discovery">
                <button className="btn btn-lg btn-primary mb-4 w-full md:w-auto">
                  {`Book a discovery call`}
                </button>
              </Link>
              <div className="font-light text-xs text-slate-800 dark:text-slate-100 text-center md:text-left">
                {`Limited availability for 2024.`}
              </div>
            </div>
            <div className="flex flex-col gap-3 justify-center lg:max-w-[528px] shrink-0 w-full">
              <div className="aspect-[4/3] overflow-hidden relative w-full">
                <Image
                  alt="Picture of Dustin Larimer, Founder of Hypervibrant"
                  className="object-cover lg:max-w-[528px] rounded-lg absolute"
                  fill={true}
                  sizes="(max-width: 768px) 100vw, 528px"
                  src="/images/dlarimer-2024.jpg"
                />
              </div>
              <span className="text-slate-600 text-xs">
                {`Dustin Larimer, Founder of Hypervibrant.`}
              </span>
            </div>
          </div>
        </Container>
      </div>
    </>
  )
}
