'use client'

import Head from 'next/head'
import Image from 'next/image'
import ImageLogo from '@public/images/hypervibrant-logo.png'

type Props = {
  className?: string;
}

export function Logo ({ className }: Props) {
  return (
    <>
      <Head>
        <link rel="preload" href={ImageLogo.src} as="image" type="image/png" />
      </Head>
      <Image
        alt="Hypervibrant Logo"
        className="dark:invert"
        height={40}
        unoptimized={true}
        src={ImageLogo.src}
        width={192}
      />
    </>
  )
}
