import clsx from 'clsx'
import Image from 'next/image'
import Link from 'next/link'
import { Container } from '../container'

interface Props {
  className?: string
}

const offerings = [
  {
    description: 'Solve big problems and validate product ideas with potential customers - in just 4 days.',
    heading: 'Design Sprints',
    href: '/design-sprints',
    imgAlt: '',
    imgSrc: '/images/sprint-workspace-1.jpg',
  },
  {
    heading: 'Strategy Sprints',
    description: 'Define a bold new vision and align your team with a clear plan for making it a reality.',
    href: '/discovery',
    imgAlt: '',
    imgSrc: '/images/sprint-workspace-2.jpg',
  },
  {
    heading: 'Retreats & Offsites',
    description: 'Create space for deep focus and reconnection, embraced by the beauty of nature.',
    href: '/discovery',
    imgAlt: '',
    imgSrc: '/images/retreat-workspace-1.jpg',
  }
]

export function Offerings({ className }: Props) {
  return (
    <>
      <div className={clsx("bg-white/70 backdrop-blur-xl py-16 xl:py-27", className)}>
        <Container>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
            {offerings.map(({ description, heading, href, imgAlt, imgSrc }, i) => (
              <Link
                className="bg-white block group rounded-xl shadow-lg hover:shadow-xl shadow-cyan-400/10 hover:shadow-cyan-500/20 focus:shadow-xl focus:shadow-cyan-500/20 focus:outline-cyan-400/80 focus:outline-offset-4 overflow-hidden transition-all duration-750 ease-in-out"
                href={href}
                key={i}
              >
                <div className="h-[168px] overflow-hidden relative">
                  <Image
                    alt={imgAlt}
                    className="scale-110 group-hover:scale-100 transition-all duration-1000 ease-in-out"
                    fill={true}
                    sizes="(max-width: 768px) 100vw, 400px"
                    src={imgSrc}
                  />
                </div>
                <div className="px-5 py-6">
                  <h3 className="font-semibold mb-2 mt-0 text-md text-rose-500 tracking-normal">
                    {heading}
                  </h3>
                  <p className="font-light leading-7 text-sm">
                    {description}
                  </p>
                </div>
              </Link>
            ))}
            {/* <Link href="/" key={i} className="bg-white block group rounded-xl shadow-lg hover:shadow-xl shadow-cyan-400/10 hover:shadow-cyan-500/20 overflow-hidden transition-all duration-750 ease-in-out">
              <div className="h-[168px] overflow-hidden relative">
                <Image
                  alt={imgAlt}
                  className="scale-110 group-hover:scale-100 transition-all duration-1000 ease-in-out"
                  fill={true}
                  sizes="(max-width: 768px) 100vw, 400px"
                  src={imgSrc}
                />
              </div>
              <div className="px-5 py-5">
                <h3 className="font-semibold mb-2 mt-0 text-md text-red-600 tracking-normal">
                  {heading}
                </h3>
                <p className="font-light leading-7 text-sm">
                  {description}
                </p>
              </div>
            </Link> */}
          </div>
        </Container>
      </div>
    </>
  )
}
