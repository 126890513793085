'use client'

import { TableOfContents } from '@/components'
 
type Props = {
  children: React.ReactNode
}

export function ArticleLayout({ children }: Props) {
  return (
    <div className="flex flex-col md:flex-row gap-10 justify-between relative pb-16">
      <div className="w-[244px] shrink-0 h-full py-4 md:-mt-4 order-last sticky top-0 hidden md:block">
        <TableOfContents />
      </div>
      <div className="article-body max-w-[624px] grow">
        {children}
      </div>
    </div>
  )
}
