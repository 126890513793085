'use client'

import {
  InlineWidget,
  useCalendlyEventListener
} from 'react-calendly'

interface CalendlyEmbedProps {
  onSubmit?: () => void
  prefill?: {
    email?: string
    firstName?: string
  }
  url: string
}

export const CalendlyInlineWidget = ({ onSubmit, prefill, url }: CalendlyEmbedProps) => {
  useCalendlyEventListener({
    // onDateAndTimeSelected: () => console.log("onDateAndTimeSelected"),
    onEventScheduled: () => (!!onSubmit && onSubmit()),
    // onEventTypeViewed: () => console.log("onEventTypeViewed"),
    // onProfilePageViewed: () => console.log("onProfilePageViewed"),
  })

  if (typeof document === undefined) {
    return null
  }

  const rootElement = document.getElementById('root')

  if (!rootElement) {
    return null
  }

  return (
    <>
      <InlineWidget
        pageSettings={{
          hideEventTypeDetails: true,
          hideGdprBanner: true,
          hideLandingPageDetails: true,
          primaryColor: '0891B2',
          textColor: '475569',
        }}
        prefill={prefill}
        url={url}
        utm={{
          // utmCampaign: 'Spring Sale 2019',
          // utmContent: 'Shoe and Shirts',
          // utmMedium: 'Ad',
          // utmSource: 'Facebook',
          // utmTerm: 'Spring'
        }}
      />
    </>
  )
}
