'use client'

import { ReactNode } from 'react'
import clsx from 'clsx'

type Props = {
  children: ReactNode
  className?: string
}

export function Container({ children, className, ...rest }: Props) {
  return (
    <div
      className={clsx('container max-w-screen-xl mx-auto px-6', className)}
      {...rest}
    >
      {children}
    </div>
  )
}
