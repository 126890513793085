import clsx from 'clsx'
import NextLink from 'next/link'

type LinkProps = React.ComponentProps<typeof NextLink>
type Props = LinkProps & {
  external?: boolean
  href: string
  title?: string
  children: React.ReactNode
  className?: string
  underline?: boolean
  tabIndex?: number
}

export const Link = (props: Props) => {
  const {
    external,
    href,
    children,
    className = '',
    title,
    underline = true,
    tabIndex = 0,
    ...rest
  } = props

  const classes = clsx(
    '',
    underline && 'no-underline',
    className
  )

  if (external) {
    return (
      <a
        href={href}
        target="_blank"
        rel="noopener noreferrer"
        className={classes}
        title={title}
        tabIndex={tabIndex}
        {...rest}
      >
        {children}
      </a>
    )
  }

  return (
    <NextLink
      href={href}
      title={title}
      className={className}
      {...rest}
      tabIndex={tabIndex}
    >
      {children}
    </NextLink>
  )
}
