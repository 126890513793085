'use client'

import clsx from 'clsx'
import { useCallback, useEffect, useState } from 'react'
import { useTheme } from 'next-themes'

function MoonIcon({ className }: { className: string }) {
  return (
    <svg className={`absolute transition-all delay-75 duration-300 ease-in-out ${className}`} width="20" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M19.8248 17.9783L19.8096 17.9782C17.8945 17.9648 16.0173 17.4426 14.3705 16.465C12.7236 15.4874 11.3662 14.0895 10.4374 12.4146C9.50857 10.7397 9.04168 8.84802 9.08458 6.9333C9.11875 5.40788 9.47551 3.91153 10.1263 2.54109C8.16962 3.11936 6.4328 4.29849 5.17052 5.92494C3.68055 7.84476 2.95843 10.2504 3.14476 12.6734C3.33109 15.0964 4.41254 17.3634 6.17852 19.0328C7.94167 20.6996 10.2612 21.6516 12.6866 21.7043C14.3457 21.7067 15.9896 21.281 17.4336 20.4706C18.5657 19.8353 19.5545 18.9797 20.3438 17.9584C20.1761 17.9687 20.0081 17.9753 19.84 17.978L19.8248 17.9783ZM12.6716 23C9.9202 22.9434 7.28833 21.8649 5.28847 19.9744C3.28862 18.0838 2.06395 15.5167 1.85295 12.7728C1.64194 10.0289 2.4597 7.30463 4.14698 5.13056C5.83427 2.95649 8.27029 1.48822 10.9807 1.01165C11.1297 0.985709 11.2829 1.0033 11.4221 1.06231C11.5614 1.12132 11.6806 1.21925 11.7655 1.34434C11.8505 1.46943 11.8975 1.61637 11.901 1.76753C11.9045 1.9187 11.8643 2.06766 11.7853 2.19655C10.9027 3.63248 10.4176 5.27724 10.3799 6.96232C10.3421 8.64741 10.753 10.3122 11.5704 11.7863C12.3879 13.2603 13.5825 14.4905 15.0318 15.3508C16.4812 16.2112 18.1332 16.6708 19.8187 16.6826C20.3031 16.6746 20.7864 16.631 21.2644 16.5521C21.4224 16.5261 21.5654 16.5454 21.7039 16.6047C21.8423 16.6639 21.9608 16.7617 22.0452 16.8864C22.1296 17.0111 22.1765 17.1574 22.1801 17.3079C22.1838 17.4584 22.1441 17.6068 22.0659 17.7355C21.0884 19.3468 19.7111 20.6781 18.0677 21.6005C16.4242 22.5229 14.5562 23.0049 12.6716 23Z" fill="currentColor" />
      <path fillRule="evenodd" clipRule="evenodd" d="M20.6144 8.06598C20.7127 8.12592 20.7821 8.2208 20.8074 8.32976C20.8327 8.43872 20.8118 8.55285 20.7493 8.64705L19.8004 10.077H21.5607C21.6772 10.077 21.7889 10.1214 21.8713 10.2003C21.9537 10.2793 22 10.3864 22 10.4981C22 10.6097 21.9537 10.7168 21.8713 10.7958C21.7889 10.8748 21.6772 10.9191 21.5607 10.9191H19.8004L20.7493 12.3486C20.8102 12.4428 20.83 12.5562 20.8043 12.6642C20.7785 12.7723 20.7094 12.8662 20.6119 12.9258C20.5144 12.9853 20.3963 13.0055 20.2833 12.9822C20.1703 12.9588 20.0714 12.8936 20.0082 12.8009L19 11.2825L17.9918 12.8009C17.9612 12.8482 17.921 12.8893 17.8737 12.9216C17.8264 12.954 17.7729 12.9771 17.7162 12.9894C17.6595 13.0018 17.6009 13.0033 17.5436 12.9938C17.4863 12.9843 17.4316 12.964 17.3825 12.9341C17.3335 12.9042 17.2912 12.8653 17.258 12.8195C17.2247 12.7738 17.2013 12.7222 17.1891 12.6678C17.1768 12.6133 17.1759 12.5571 17.1865 12.5023C17.1971 12.4475 17.2189 12.3953 17.2507 12.3486L18.1996 10.9191H16.4393C16.3228 10.9191 16.2111 10.8748 16.1287 10.7958C16.0463 10.7168 16 10.6097 16 10.4981C16 10.3864 16.0463 10.2793 16.1287 10.2003C16.2111 10.1214 16.3228 10.077 16.4393 10.077H18.1996L17.2507 8.64747C17.1881 8.55328 17.1671 8.43909 17.1923 8.33004C17.2175 8.22099 17.2868 8.12601 17.3851 8.06598C17.4834 8.00596 17.6025 7.98581 17.7163 8.00997C17.8301 8.03414 17.9292 8.10063 17.9918 8.19483L19 9.71361L20.0082 8.19525C20.0707 8.10108 20.1697 8.03457 20.2834 8.01033C20.3971 7.98609 20.5162 8.00611 20.6144 8.06598Z" fill="currentColor" />
      <path fillRule="evenodd" clipRule="evenodd" d="M17.0763 2.04399C17.1418 2.08395 17.1881 2.1472 17.2049 2.21984C17.2218 2.29248 17.2078 2.36856 17.1662 2.43137L16.5336 3.38466H17.7071C17.7848 3.38466 17.8593 3.41424 17.9142 3.46688C17.9691 3.51952 18 3.59092 18 3.66537C18 3.73982 17.9691 3.81122 17.9142 3.86386C17.8593 3.91651 17.7848 3.94608 17.7071 3.94608H16.5336L17.1662 4.89909C17.2068 4.96187 17.22 5.03748 17.2028 5.1095C17.1857 5.18152 17.1396 5.24417 17.0746 5.28384C17.0096 5.32351 16.9309 5.33702 16.8555 5.32144C16.7802 5.30585 16.7143 5.26242 16.6721 5.20058L16 4.18833L15.3279 5.20058C15.3074 5.23214 15.2807 5.2595 15.2491 5.28109C15.2176 5.30267 15.1819 5.31803 15.1441 5.3263C15.1064 5.33456 15.0672 5.33556 15.0291 5.32923C14.9909 5.3229 14.9544 5.30937 14.9217 5.28942C14.889 5.26947 14.8608 5.24351 14.8386 5.21303C14.8165 5.18254 14.8009 5.14816 14.7927 5.11185C14.7845 5.07554 14.7839 5.03804 14.791 5.00152C14.7981 4.965 14.8126 4.93019 14.8338 4.89909L15.4664 3.94608H14.2929C14.2152 3.94608 14.1407 3.91651 14.0858 3.86386C14.0309 3.81122 14 3.73982 14 3.66537C14 3.59092 14.0309 3.51952 14.0858 3.46688C14.1407 3.41424 14.2152 3.38466 14.2929 3.38466H15.4664L14.8338 2.43165C14.792 2.36885 14.778 2.29273 14.7948 2.22003C14.8116 2.14733 14.8579 2.084 14.9234 2.04399C14.9889 2.00397 15.0684 1.99054 15.1442 2.00665C15.2201 2.02276 15.2861 2.06709 15.3279 2.12989L16 3.14241L16.6721 2.13017C16.7138 2.06739 16.7798 2.02305 16.8556 2.00689C16.9314 1.99073 17.0108 2.00407 17.0763 2.04399Z" fill="currentColor" />
    </svg>
  )
}

function SunIcon({ className }: { className: string }) {
  return (
    <svg className={`absolute transition-all delay-75 duration-300 ease-in-out ${className}`} width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M7.92871 12.0001C7.92871 13.0799 8.35766 14.1155 9.1212 14.8791C9.88475 15.6426 10.9203 16.0716 12.0001 16.0716C13.0799 16.0716 14.1155 15.6426 14.8791 14.8791C15.6426 14.1155 16.0716 13.0799 16.0716 12.0001C16.0716 10.9203 15.6426 9.88475 14.8791 9.1212C14.1155 8.35766 13.0799 7.92871 12.0001 7.92871C10.9203 7.92871 9.88475 8.35766 9.1212 9.1212C8.35766 9.88475 7.92871 10.9203 7.92871 12.0001ZM8.06054 15.9397C7.0157 14.8949 6.42871 13.4778 6.42871 12.0001C6.42871 10.5225 7.0157 9.10539 8.06054 8.06054C9.10539 7.0157 10.5225 6.42871 12.0001 6.42871C13.4778 6.42871 14.8949 7.0157 15.9397 8.06054C16.9846 9.10539 17.5716 10.5225 17.5716 12.0001C17.5716 13.4778 16.9846 14.8949 15.9397 15.9397C14.8949 16.9846 13.4778 17.5716 12.0001 17.5716C10.5225 17.5716 9.10539 16.9846 8.06054 15.9397Z" fill="currentColor" />
      <path fillRule="evenodd" clipRule="evenodd" d="M12.75 1C12.75 0.585786 12.4142 0.25 12 0.25C11.5858 0.25 11.25 0.585786 11.25 1V4C11.25 4.41421 11.5858 4.75 12 4.75C12.4142 4.75 12.75 4.41421 12.75 4V1ZM23 12.75C23.4142 12.75 23.75 12.4142 23.75 12C23.75 11.5858 23.4142 11.25 23 11.25H20C19.5858 11.25 19.25 11.5858 19.25 12C19.25 12.4142 19.5858 12.75 20 12.75H23ZM3.69135 3.69146C3.98424 3.39857 4.45912 3.39857 4.75201 3.69146L6.87333 5.81278C7.16622 6.10568 7.16622 6.58055 6.87333 6.87344C6.58044 7.16634 6.10556 7.16634 5.81267 6.87344L3.69135 4.75212C3.39846 4.45923 3.39846 3.98436 3.69135 3.69146ZM20.3085 4.75201C20.6014 4.45912 20.6014 3.98424 20.3085 3.69135C20.0156 3.39846 19.5408 3.39846 19.2479 3.69135L17.1266 5.81267C16.8337 6.10556 16.8337 6.58044 17.1266 6.87333C17.4195 7.16622 17.8943 7.16622 18.1872 6.87333L20.3085 4.75201ZM12 19.25C12.4142 19.25 12.75 19.5858 12.75 20V23C12.75 23.4142 12.4142 23.75 12 23.75C11.5858 23.75 11.25 23.4142 11.25 23V20C11.25 19.5858 11.5858 19.25 12 19.25ZM4 12.75C4.41421 12.75 4.75 12.4142 4.75 12C4.75 11.5858 4.41421 11.25 4 11.25H1C0.585786 11.25 0.25 11.5858 0.25 12C0.25 12.4142 0.585786 12.75 1 12.75H4ZM17.1269 17.1265C17.4198 16.8336 17.8947 16.8336 18.1876 17.1265L20.3089 19.2478C20.6018 19.5407 20.6018 20.0156 20.3089 20.3085C20.016 20.6014 19.5411 20.6014 19.2482 20.3085L17.1269 18.1872C16.834 17.8943 16.834 17.4194 17.1269 17.1265ZM6.87299 18.1871C7.16588 17.8942 7.16588 17.4193 6.87299 17.1264C6.5801 16.8335 6.10522 16.8335 5.81233 17.1264L3.69101 19.2477C3.39812 19.5406 3.39812 20.0155 3.69101 20.3084C3.9839 20.6013 4.45878 20.6013 4.75167 20.3084L6.87299 18.1871Z" fill="currentColor" />
    </svg>
  )
}

type Props = {
  className?: string
}

export function ThemeToggle({ className }: Props) {
  const [ mounted, setMounted ] = useState(false)
  const { resolvedTheme, theme, setTheme } = useTheme()

  const onToggle = useCallback(() => {
    setTheme(theme === 'dark' ? 'light' : 'dark')
  }, [setTheme, theme])

  useEffect(() => {
    setMounted(true)
  }, [])

  if (!mounted) {
    return null
  }

  return (
    <>
      <button
        className={clsx(
          'btn bg-slate-900 text-cyan-300 hover:text-white flex justify-center items-center h-10 w-10 rounded-full md:rounded overflow-hidden relative transition-all delay-75 duration-300 ease-in-out',
          className
        )}
        onClick={onToggle}
      >
        <MoonIcon className={resolvedTheme === 'light' ? '-translate-y-12' : ''} />
        <SunIcon className={resolvedTheme === 'dark' ? 'translate-y-16' : ''} />
      </button>
    </>
  )
}
