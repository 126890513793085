'use client'

import { Container, Link } from '@/components'
import { siteConfig } from '@/config'
 
export function Footer() {
  return (
    <div className="py-8">
      <Container>
        <div className="flex flex-col md:flex-row font-light justify-between leading-6 text-xs">
          <div className="order-last md:order-first text-center md:text-left text-slate-800 dark:text-slate-300">
            &copy; {`${new Date().getFullYear()}`}
            <span className="opacity-40">{` • `}</span>
            {`All rights reserved`}
          </div>
          <div className="flex items-center gap-4">
            <ul className="flex flex-row justify-center md:justify-start mb-2 md:mb-0 space-x-4">
              <li>
                <Link className="text-slate-800" href="/blog">Blog</Link>
              </li>
              <li>
                <Link className="text-slate-800" href="/privacy">Terms of Service</Link>
              </li>
              <li>
                <Link className="text-slate-800" href="/privacy">Privacy Policy</Link>
              </li>
            </ul>
            <ul className="flex flex-row items-center space-x-2">
              {siteConfig.socials.map(({ Component, href = '', title = '' }, i) => (
                <li key={i}>
                  <Link
                    className="text-slate-800 hover:text-black dark:text-white dark:opacity-60 dark:hover:opacity-100 transition-colors duration-100"
                    external={true}
                    href={href}
                    title={title}
                  >
                    <Component className="h-6 w-6" />
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </Container>
    </div>
  )
}
